// Here you can add other styles

.osrit-bc-scheduled {
  background-color: #007bff;
}

.osrit-bc-delivered {
  background-color: #28a745;
}

.osrit-bc-refused {
  background-color: #dc3545;
}

.osrit-bc-paid {
  background-color: #28a745;
}

.osrit-bc-invoicePending {
  background-color: #ffc107;
}

.osrit-bc-inTransit {
  background-color: #ffc107;
}

.osrit-txt-scheduled {
  color: red;
}
// Date InputDatePicker

.react-datepicker-wrapper {
  width: 100%;
}

div.react-datepicker-popper {
  z-index: 9;
}

.react_osrit_date_picker {
  width: 100%;
  border: 1px solid #e3e7ea;
  padding: 0.25rem 0.5rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  min-height: 32px;
  color: #5d6b75;

  &.error {
    border: 1px solid #f86c6b;
  }
}

div.osrit_date_widget {
  width: 100%;
  border: 1px solid #e5e7ea;
  padding: 0.25rem 0.5rem;
  // font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem;

  input {
    padding-left: 0;
  }

  .rw-filter-input,
  .rw-widget-input {
    box-shadow: none;
  }

  &.error {
    border: 1px solid #f86c6b;
  }
}

.osrit_dispatch_data {
  &.row {
    margin-top: 0;
    margin-bottom: 2px;
  }

  &.small {
    margin-bottom: 0;

    dd,
    dt {
      font-size: 10px;
    }
  }

  dt {
    margin-bottom: 0.1rem;
    padding-right: 0;
  }

  dd {
    margin-bottom: 0.1rem;
    padding-right: 0;
  }
}

.osrit_more_button {
  color: #2196f3;
  cursor: pointer;
  margin-left: 5px;
}

.osrit_checkbox {
  label {
    font-size: 15px;
    cursor: pointer;
  }
  /*** custom checkboxes ***/
  input[type="checkbox"] {
    display: none;
  }
  /* to hide the checkbox itself */
  input[type="checkbox"] + label:before {
    font-family: FontAwesome;
    display: inline-block;
  }

  input[type="checkbox"] + label:before {
    content: "\f096";
  }

  input[type="checkbox"]:disabled + label:before {
    content: "\f0c8";
    color: #ccc;
  }
  /* unchecked icon */
  input[type="checkbox"] + label:before {
    letter-spacing: 10px;
  }
  /* space between checkbox and label */
  input[type="checkbox"]:checked + label:before {
    content: "\f046";
  }
  /* checked icon */
  input[type="checkbox"]:checked + label:before {
    letter-spacing: 5px;
    color: #21a8d8;
  }
}

table.osrit_table_hover_dark {
  tr:hover {
    background-color: #20a8d840;
  }
}

.rc-steps.rc-steps-horizontal.rc-steps-label-vertical {
  .rc-steps-item-container {
    .rc-steps-item-tail {
      margin-left: 65px !important;
      width: 95%;
    }

    .rc-steps-item-icon {
      min-height: 40px;
    }

    .rc-steps-item-content {
      margin-left: 15px;
    }
  }

  .item_not_selected {
    .rc-steps-item-title {
      color: #ccc;
    }
  }

  .item_selected {
    .rc-steps-item-title {
      color: black;
      font-weight: 800;
      font-size: 20px;
    }
  }
}

.modal-body {
  padding: 30px;
}

.card {
  &.top_nav {
    border-top: 0;
  }
}

.alert {
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

ol.breadcrumb {
  margin-bottom: 5px;
}

.animated {
  margin: 0 -20px;

  &.no_sc_fix {
    margin: 0 -10px;

    .card {
      margin-bottom: 10px;
    }

    .col-md-12,
    .col-md-4 {
      padding: 0 5px;
    }
  }
}

.osrit_manager_table {
  .availabe_loads_wrapper {
    .table-responsive {
      max-height: 400px;
      min-height: 100px;
    }
  }

  .in_route_wrapper {
    .table-responsive {
      max-height: 1000px;
      min-height: 150px;
    }
  }

  .availability_wrapper {
    .table-responsive {
      min-height: 700px;
      max-height: 1200px;
    }
  }
}

.osrit_bol_table {
  width: 100%;

  table {
    border-collapse: collapse;
    width: 100%;
  }

  table,
  td,
  th {
    border: 1px solid black;
  }

  th {
    background-color: #ccc;
  }

  td,
  th {
    padding: 4px;
  }
}

div.line_div_only {
  background-color: #c8ced3;
  height: 1px;
  margin: 10px auto;
  width: 95%;
}

.boxShadow {
  box-shadow: rgb(119, 119, 119) 1px 2px 10px 0;
  background-color: white !important;
}

.right-menu {
  position: absolute;
  z-index: 2;
  right: 20px;
  max-height: 1400%;
  overflow: scroll;
}

.css-yk16xz-control {
  min-height: 20px !important;
}

.css-1pahdxg-control {
  min-height: 20px !important;
}

.css-tlfecz-indicatorContainer {
  padding: 3px !important;
}

.css-1gtu0rj-indicatorContainer {
  padding: 3px !important;
}

.css-g1d714-ValueContainer {
  padding: 1px 8px !important;
}

.select__menu {
  background-color: #28a745;
  z-index: 10000;
}

.login-card {
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.18);
  margin-left: 10px;
}

.red {
  color: red;
}

.centered-60 {
  position: absolute;
  left: 60%;
  transform: translate(-40%, 0%);
}
@media (max-width: 576px) {
  .app {
    max-height: 70vh;
    min-height: 60vh;
  }

  .login-card {
    box-shadow: none;
    margin-left: 0;
  }
}

.carousel-indicators {
  li {
    background-color: black;
  }
}

.custom-form .form-check-input {
  position: relative;
  margin-left: 8px;
}

.modal-content {
  border: none;
}

#root {
  background-color: #e4e5e6 !important;
}

body {
  background-color: transparent !important;
}

.react-pdf__Page__canvas {
  margin: auto;
}

.osrit-dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 30px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #23282c99;
  outline: none;
  transition: border 0.24s ease-in-out;
}
